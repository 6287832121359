const headers = [
  {
    text: "Status",
    align: "center",
    sortable: false,
    value: "isChecked",
  },
  {
    text: "Device Path",
    align: "start",
    sortable: false,
    value: "devicePath",
  },
  { text: "Device Name", value: "device.name" },
  { text: "Created At", value: "timestamp" },
  { text: "Info", value: "info" },
  // { text: "Notes", value: "notes" },
  { text: "Actions", value: "actions", sortable: false },
];

export { headers };
