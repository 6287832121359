<template>
  <v-card class="card-shadow">
    <div class="card-header-padding card-border-bottom">
      <div class="font-weight-600 text-h3 text-typo mb-1">
        Alarms
      </div>
      <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
        You can check all alarms from this
      </p>
    </div>

    <v-card-text class="px-0 py-0">
      <v-data-table :headers="headers" :items="logs" :loading="isLoading" :page.sync="page"
        :items-per-page="itemsPerPage" :no-data-text="errorMsg" mobile-breakpoint="0" :server-items-length="totalItems"
        @update:options="updateTable" :footer-props="{'items-per-page-options': [5, 10, 20, 50]}">
        <template v-slot:top>
          <v-toolbar flat height="80" width="100%" class="justify-space-between">
            <v-text-field hide-details class="
                input-style
                font-size-input
                text-light-input
                placeholder-light
                input-icon
              " outlined height="40" v-on:input="debouncedSearchCallback" placeholder="Search">
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
              </template>
            </v-text-field>

            <v-spacer />

            <v-btn @click="fetchLogs" :loading="isLoading" :ripple="false" height="40" outlined
              class="mr-2 text-capitalize" color="success">
              <v-icon size="16">mdi-refresh</v-icon> Refresh
            </v-btn>
            <v-btn @click="removeAllAlarm" :ripple="false" height="40" :loading="isDeleting" outlined
              class="text-capitalize btn-ls btn-warning" color="#fb6340">
              <v-icon size="16">mdi-delete</v-icon> Remove All Alarms
            </v-btn>

            <v-dialog v-model="showEditNoteDialog" max-width="500px">
              <v-card class="card-shadow">
                <div class="card-header-padding card-border-bottom">
                  <span class="font-weight-600 text-h3 text-typo mb-0">
                    Update Notes
                  </span>
                </div>
                <v-card-text class="card-padding">
                  <v-container class="px-0">
                    <v-row>
                      <v-col cols="12">
                        <label class="text-xs text-typo font-weight-bolder">Notes: </label>
                        <v-text-field v-model="notes" hide-details class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            " dense flat filled solo height="43" placeholder="Enter your note"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions class="card-padding d-flex justify-end">
                  <v-btn @click="saveLog" elevation="0" :loading="isSaving" :ripple="false" height="43" class="
                        font-weight-600
                        text-capitalize
                        btn-ls btn-primary
                        py-3
                        px-6
                        rounded-sm
                      " color="#5e72e4">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="showInfoDialog" max-width="500px">
              <v-card class="card-shadow card-padding">
                <v-card-title class="pt-0 text-h4 text-typo justify-center">
                  Alarm Info
                </v-card-title>
                <v-card-text>
                  <label class="text-xs text-typo font-weight-bolder">Notes: </label>
                  <p class="mt-1 mb-0 text-body font-weight-normal">
                    {{selectedLog.notes || 'Not add note yet!'}}
                  </p>
                </v-card-text>
                <v-card-actions class="pb-0 justify-end">
                  <v-btn @click="closeDialog" elevation="0" :ripple="false" height="43" class="
                        font-weight-600
                        text-capitalize
                        btn-ls btn-primary
                        py-3
                        px-6
                        rounded-sm
                      " color="#5e72e4">Ok</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.isChecked="{ item }">
          <v-btn style="cursor: default;" :ripple="false" fab x-small outlined size="16"
            :color="item.isChecked ? 'success': 'grey'">
            <v-icon size="12">mdi-check</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.device.name="{ item }">
          {{item.device ? item.device.name : '-'}}
        </template>

        <template v-slot:item.timestamp="{ item }">
          {{formatTime(item.timestamp)}}
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="d-flex no-wrap">
            <v-btn @click="editNoteLog(item)" :ripple="false" height="32" min-width="32" width="32" outlined
              class="font-weight-600 text-capitalize btn-ls btn-warning mr-2" color="#fb6340">
              <v-icon size="12">mdi-check</v-icon>
            </v-btn>

            <v-btn @click="showDialogInfo(item)" :ripple="false" height="32" min-width="32" width="32" outlined
              class="font-weight-600 text-capitalize btn-ls btn-danger" color="#f5365c">
              <v-icon size="12">mdi-information-variant</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import { headers } from "./AlarmsConstants";

export default {
  name: "Alarms-Page",
  data() {
    return {
      page: 1,
      itemsPerPage: 10,
      totalItems: 0,
      sortBy: [],
      sortDesc: false,
      showEditNoteDialog: false,
      showInfoDialog: false,
      logs: [],
      selectedLog: {},
      headers,
      search: "",
      notes: "",
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      errorMsg: '',
    };
  },
  created() {
    this.debouncedSearchCallback = debounce(value => {
      this.search = value.trim();
      this.page = 1;
      this.fetchLogs();
    }, 300);
  },
  methods: {
    formatTime(timestamp) {
      return dayjs(timestamp).format('DD.MM.YYYY HH:MM:ss');
    },
    fetchLogs: async function () {
      try {
        this.isLoading = true;
        this.errorMsg = '';
        let endpoint = `/api/alarm?page=${this.page - 1}&perPage=${this.itemsPerPage}`;

        if (this.search) {
          endpoint += `&search=${this.search}`;
        }

        if (this.sortBy.length) {
          endpoint += `&sortBy=${this.sortBy}&sortDesc=${this.sortDesc}`;
        }

        const { data: { data, totalData } } = await this.$axios.get(endpoint);
        this.logs = data;
        this.totalItems = totalData;
      } catch (error) {
        this.errorMsg = "Can't load data. Please try again later!";
      }
      this.isLoading = false;
    },

    removeAllAlarm: async function () {
      this.isDeleting = true;
      try {
        await this.$axios.delete('/api/alarm/all');
        this.logs = [];
        this.page = 1;
        this.search = '';
        this.sortBy = [];
        this.fetchLogs();
      } catch (error) {
        console.log(error);
      }
      this.isDeleting = false;
    },

    updateTable(opt) {
      this.page = opt.page;
      this.itemsPerPage = opt.itemsPerPage;
      this.sortBy = opt.sortBy;
      this.sortDesc = opt.sortDesc;
      this.fetchLogs();
    },

    async editNoteLog(item) {
      this.showEditNoteDialog = true;
      this.selectedLog = { ...item };
      this.notes = this.selectedLog.notes || '';

      if (!item.isChecked) {
        try {
          await this.$axios.put('/api/alarm/' + item._id, { notes: item.notes || '' });
          this.logs = this.logs.map(log => {
            if (log._id === item._id) {
              log.isChecked = true;
            }
            return log;
          });
        } catch (error) {
          console.log(error);
        }
      }
    },

    showDialogInfo(item) {
      this.showInfoDialog = true;
      this.selectedLog = item;
    },

    closeDialog() {
      this.showEditNoteDialog = false;
      this.showInfoDialog = false;
      this.selectedLog = {};
    },

    async saveLog() {
      if (this.notes.trim().length > 0) {
        this.isSaving = true;
        try {
          await this.$axios.put('/api/alarm/' + this.selectedLog._id, { notes: this.notes.trim() });
          this.logs = this.logs.map(log => {
            if (log._id === this.selectedLog._id) {
              log.notes = this.notes.trim();
            }
            return log;
          });
        } catch (error) {
          console.log(error);
        }
        this.isSaving = false;
      }
      this.closeDialog();
    },
  },
};
</script>
